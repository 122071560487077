import React from 'react';
import axios from 'axios';
import ReactPlayer from "react-player";
import './Vids.css';

import {BrowserRouter as Router} from 'react-router-dom';
import Route from 'react-router-dom/Route';

class Vids extends React.Component {
    //initialize an object's state in a class
     constructor(props) {
       super(props)
         this.state = {
           data: []
                 }
         }
         //ComponentDidMount is use to Connect a React app to external applications, such as web APIs or JavaScript functions
         componentDidMount(){
           //get request
           {/*  
           axios.get('http://www.whiteheadgallery.io/php/get_video.php').then(res => 
           {
           
           this.setState({data: res.data});
              }); 
            */}
           }
           
       
    
     render() {
       
       return (

        <div>
        <div className="opening-body">
           
                <p>May 1, 2021: Assorted videos that I have put together recently. Some of these take a significant amount of computer time to make;
                  I'll try to add more as time allows.
                </p>
                <br/>
                <div className="movie-container">
                <ReactPlayer
                    url="http://www.whiteheadgallery.io/videos/video-9.mp4"
                    controls />
                </div>
                <div className="movie-container">
                <ReactPlayer
                    url="http://www.whiteheadgallery.io/videos/noise_v1.mp4"
                    controls width="352" height="352"
                  />
                </div>
                <div className="movie-container">
                <ReactPlayer
                    url="http://www.whiteheadgallery.io/videos/UT_paint_fv.mp4"
                    controls width="640" height="480"
                  />
                </div>
                
         
         </div><br/><br/><br/><br/>
         </div>
       )
     };
   }
   export default Vids;


