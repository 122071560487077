import React from 'react';
import './Art.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

function Navbar() {

    return (
    <div class="endtitle" id="showMe">
        <Link to="/"><span className="nav_text">Whitehead Gallery</span></Link>
        <div className="border-gradient menu-items">

        <Link to="/donuts">
          <img className="image-links" 
          src="./images/pho_icon.png" data-tip="2D Things"
          onMouseOver={e => (e.currentTarget.src = "./images/pho_icon_b.png")}
          onMouseOut={e => (e.currentTarget.src = "./images/pho_icon.png")}/>
          <ReactTooltip type="light" borderColor="#000" border="true" className="popup_b"/>
        </Link>

        <Link to="/binary">
        <img className="image-links" 
          src="./images/bin_icon.png" data-tip="Binary Art"
          onMouseOver={e => (e.currentTarget.src = "./images/bin_icon_b.png")}
          onMouseOut={e => (e.currentTarget.src = "./images/bin_icon.png")}/>
          <ReactTooltip type="light" borderColor="#000" border="true" className="popup_b"/>
        </Link>

        <Link to="/video">
        <img className="image-links" 
          src="./images/vid_icon.png" data-tip="Motion Art"
          onMouseOver={e => (e.currentTarget.src = "./images/vid_icon_b.png")}
          onMouseOut={e => (e.currentTarget.src = "./images/vid_icon.png")}/>
          <ReactTooltip type="light" borderColor="#000" border="true" className="popup_b"/>
        </Link>

        <Link to="/abstract">
        <img className="image-links" 
          src="./images/abs_icon.png" data-tip="Abstract Art"
          onMouseOver={e => (e.currentTarget.src = "./images/abs_icon_b.png")}           
          onMouseOut={e => (e.currentTarget.src = "./images/abs_icon.png")}/>
          <ReactTooltip type="light" borderColor="#000" border="true" className="popup_b"/>
        </Link>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        
        <Link to="/about">
          <img className="intro-websites" src="./images/i_icon.png" data-tip="Info" />
          <ReactTooltip type="light" borderColor="#000" border="true" className="popup_b"/>
        </Link>
    </div>
    
  </div>
      
    
  );
}

export default Navbar;
