import React from 'react';
import axios from 'axios';
import Art from './Art';

import {BrowserRouter as Router} from 'react-router-dom';
import Route from 'react-router-dom/Route';

class Binary extends React.Component {
    //initialize an object's state in a class
     constructor(props) {
       super(props)
         this.state = {
           data: []
                 }
         }
         //ComponentDidMount is use to Connect a React app to external applications, such as web APIs or JavaScript functions
         componentDidMount(){
           //get request
           
           axios.get('http://www.whiteheadgallery.io/php/get_binary.php').then(res => 
           {
           
           this.setState({data: res.data});
              }); 
           
           }
           
       
    
     render() {
       
       return (
        <div className="opening-body">
        <div className="art_cards">    
           {this.state.data.map((result) => {
              return ( 
                <Art title={result.title} size={result.size} imgurl={result.imgurl} comment={result.comment}/>
                )
            })}
         </div>
         </div>
        
       )
     };
   }
   export default Binary;


