import React, { useState, useRef, useCallback }from 'react';
import './App.css';
import TotalArt from './TotalArt';
import axios from 'axios';


class Body extends React.Component {
  //initialize an object's state in a class
   constructor(props) {
     super(props)
       this.state = {
         data: []
               }
       }
       //ComponentDidMount is use to Connect a React app to external applications, such as web APIs or JavaScript functions
       componentDidMount(){
         //get request
         
         axios.get('http://www.whiteheadgallery.io/php/save2.php').then(res => 
         {
         
         this.setState({data: res.data});
            }); 
         
         }
         
         
     
  
   render() {
    console.log(this.state.data);
  return (
      
        <div className="opening-body">
          

          <div className="body__header">
            
            <div className="body__banner">
            <div className="body__banner_card"><img src="./images/ban1.jpg"/></div>
            <div className="body__banner_card"><img src="./images/ban2.jpg"/></div>
            <div className="body__banner_card"><img src="./images/ban3.jpg"/></div>
            <div className="body__banner_card"><img src="./images/ban4.jpg"/></div>
            </div>
            <div className="open-sections">
              <div className="welcome_wg">
              Digital Art from Whitehead
            </div>
            
            <br/>
            <p>  There is no need to 
              dust off your pirate hats! I encourage you to copy, paste, save as and then, print, hang, and resell whatever 
              you find here.  It was my pleasure to create this page and all of its content.  
              The categories are below on the right and include 2D things, binary art, 
              video stuff, and abstract junk that may have some aesthetic or technical value.</p>
            </div>
            <div className="open-sections">
            <br/>This page's front end was built in React and javascript.  The backend is PHP and MySQL database.  
              <br/><br/>Recent updates:  Backend is PHP and MySQL and I fixed a text rotation problem with Chrome.  The cards are 
                being pulled from the database and the entry tool is now done; the database is sending props to the components as designed.  
            </div>
            <br/>
            <div className="donuts">
            
            
            {this.state.data.map((result) => {
              return ( <TotalArt numart={result.arttotal} />)
            })}
          </div>

            
        
           
            
          </div>

          
          

          
          
        </div>
      
      
    
  );
  }
}

export default Body;




