import React, { useState, useRef, useCallback }from 'react';
import './App.css';

class About extends React.Component {
  //initialize an object's state in a class
   constructor(props) {
     super(props)
       this.state = {
         
               }
       }

    render() {
    
        return (
      
            <div className="opening-body">

            </div>

        );
    }
}

export default About;




