import React from 'react';
import './App.css';


function Ball() {
  return (
    <div id="field">
        <img src="./images/marble.png" id="ball"/>
        <img src="./images/wg_icon2.png" id="icon"/>
    </div>
      
    
  );
}

export default Ball;