import React from 'react';
import './Art.css';
import ReactTooltip from 'react-tooltip';


function Art({ title, size, imgurl, comment }) {
    
    let smallimgurl = imgurl.replace(".", "_s.");
    //console.log("img url: " + smallimgurl);
    return (
        
        <div className="art__container" >
            <a href={"./digital_art/" + imgurl} target="_blank">
                <img src={"/digital_art/" + smallimgurl} alt={comment} data-tip={comment}/>
                <ReactTooltip type="light" borderColor="#000" border="true" className="popup_b"/></a>
            Title: {title}<br/>
            Size: {size}<br/>
            
        </div>
    );
}
export default Art;
