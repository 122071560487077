import React from 'react';
import './App.css';

import axios from 'axios';

import Body from './Body';
import Navbar from './Navbar';
import Ball from './Ball';
import Donuts from './Donuts';
import Binary from './Binary';
import Vids from './Vids';
import Abstract from './Abstract';
import About from './About';

import {BrowserRouter as Router, Switch, Link, NavLink} from 'react-router-dom';
import Route from 'react-router-dom/Route';



class App extends React.Component {
 //initialize an object's state in a class
  constructor(props) {
    super(props)
      this.state = {
        data: []
              }
      }
      //ComponentDidMount is use to Connect a React app to external applications, such as web APIs or JavaScript functions
      componentDidMount(){
        //get request
        
        //axios.get('http://www.whiteheadgallery.io/save2.php').then(res => 
        //{
        //
        //this.setState({data: res.data});
        //   }); 
        //
        }
        
    
 
  render() {
    
    return (
     
      <div className="maincontainer">    
        <Router>
          <Ball/>
          <Switch>
            <Route path="/" exact component={Body} />
            <Route path="/donuts" component={Donuts} />
            <Route path="/binary" component={Binary} />
            <Route path="/video" component={Vids} />
            <Route path="/abstract" component={Abstract} />
            <Route path="/about" component={About} />
          </Switch>
          
          <Navbar/>  
          
          
        </Router>
        
        
      </div>
     
    )
  };
}
export default App;